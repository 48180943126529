<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="'rgba(66, 66, 66, .9)'"
    :hide-on-scroll="$vuetify.breakpoint.smAndDown"
    app
    dark
    elevate-on-scroll
  >
    <language-switcher v-slot="{ links }">
      <router-link v-for="link in links" :key="link.langIndex" :to="link.url">
        <img :src="getCountryFlagURL(link.langIndex)" alt="" />
        <span>{{ link.langName }}</span>
      </router-link>
      <v-spacer />
    </language-switcher>
    <v-spacer />
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :active-class="!isScrolling ? 'primary--text' : undefined"
        :to="item.to"
        :href="item.href"
        text
      >
        <span v-text="item.text" />
      </v-btn>
    </v-toolbar-items>
    <v-app-bar-nav-icon v-else aria-label="Open Navigation Drawer" @click="toggleDrawer" />
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from 'vuex';

export default {
  data: () => ({
    isScrolling: false,
  }),

  computed: {
    items() {
      return [
        {
          to: '/',
          text: 'Home',
        },
        {
          to: '/stugan',
          text: 'Stugan',
        },
        {
          to: '/activities',
          text: 'Att göra',
        },
        {
          href: 'https://www.airbnb.com/h/majostuganfunasfjallen',
          text: 'Pris/Bokning',
        },
        {
          to: '/trivsel',
          text: 'Boendeinfo',
        },
      ];
    },
  },

  methods: {
    ...mapMutations(['toggleDrawer']),
    onScroll() {
      this.isScrolling = (window.pageYOffset || document.documentElement.scrollTop || 0) > 25;
    },
    getCountryFlagURL(locale) {
      // Map locales to country codes to use with Country Flags API
      const map = {
        en: 'gb',
        se: 'se',
      };
      return `https://flagcdn.com/24x18/${map[locale]}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.router-language-switcher {
  margin-bottom: 20px;

  a {
    margin: 0 5px;
  }
  img {
    vertical-align: middle;
    margin: 0 5px;
  }
}
</style>
